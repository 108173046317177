@charset "UTF-8";
/* GENERALS*/
.headinghtml5 {
  left: -2000em;
  position: absolute; }

.pcaptcha {
  font-size: 12px !important;
  font-weight: normal !important; }

.fonterror {
  color: red;
  font-size: 13px; }

/* * Class for elements that are only visible to the screen reader. From * https://www.paciellogroup.com/blog/2012/05/html5-accessibility-chops-hidden-and-aria-hidden/ */
.visually-hidden {
  clip: rect(1px 1px 1px 1px);
  /* IE 6/7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  margin: -1px; }

/* * For `.visually-hidden` elements that should be visible when it gains focus. */
.visible-when-focused:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  white-space: normal;
  width: auto;
  margin: auto; }

/* * Style for "Skip Navigation" type links.  Should have an href linked to * a `.bypass-block-target` element. */
.bypass-block-link {
  position: absolute;
  text-decoration: none;
  background: #ffffcc;
  padding: 0.2em;
  z-index: 10; }

/* * We don't want the `.bypass-block-target` to have an outline on *just* focus, * since this will look strange if regular users click inside this element, * since it will look like it was tabbed into */
.bypass-block-target:focus {
  outline: none; }

.bypass-block-link:focus {
  position: absolute;
  text-decoration: none;
  background: #ffffcc;
  padding: 0.2em;
  z-index: 1000000; }

/* * We do, however, want the `.bypass-block-target` to have an outline when * it has focus and it is the target of the document (i.e. the hash tag of the * document URL is the same as the "Skip Nav" link). * * Note that this style is the same as the focus state on all the tabbable * elements.  It doesn't have to be.  WCAG 2.4.7 — Focus Visible (Level AA) * only requires that the focus state is visible, so you can have, say, the * focus state of a button different than that of a form element. */
.bypass-block-target:focus:target, [tabindex="0"]:focus, [tabindex="1"]:focus, a:focus, button:focus, input:focus {
  outline: solid 2px #427740; }

.focusoutline:focus {
  outline: 5px auto #427740; }

/*Header*/
header {
  border-bottom: 2px solid #7293a7; }

header p {
  margin: 0; }

.headerphone {
  padding-top: 10px;
  letter-spacing: 1px;
  line-height: 25px; }

.headerphone a {
  font-weight: 900;
  text-decoration: none; }

header .btn {
  margin-left: 2px;
  padding: 9px 20px;
  margin-bottom: -2px;
  font-size: 17px; }

.navbar-text {
  padding-top: 2px;
  padding-bottom: 2px; }

.mosaicheader {
  width: 68px; }

.navbar.fixed-top {
  top: 43px;
  background-color: #fbfbf3 !important;
  padding: 0;
  border-bottom: 5px solid #758699; }

.navbar-brand {
  text-decoration: none !important;
  font-weight: 900;
  font-size: 24px; }

.navbar .navbar-brand img {
  width: 208px; }

.navbar-nav a {
  color: #000 !important;
  text-decoration: none !important;
  margin: 0;
  font-size: 17px;
  padding: 48px 16px 29px !important;
  transition: all 0.4s ease;
  outline: none !important;
  border-bottom: 8px solid rgba(255, 255, 255, 0);
  position: relative;
  text-transform: uppercase; }

.navbar-nav a::after {
  content: "";
  display: block;
  background: #b9c1c6;
  position: absolute;
  right: 0;
  top: 49px;
  width: 2px;
  height: 21px; }

.navbar-nav a:last-child::after {
  display: none; }

.navbar-nav a:hover, .navbar-nav a:focus, .navbar-nav a.active {
  border-bottom: 8px solid #627384; }

.navbar.onfloat {
  background: #FFF !important;
  top: 0;
  min-height: 49px; }

/*Footer*/
footer {
  background: #eeeeee;
  padding-top: 60px; }

.rowfooter {
  margin-bottom: 30px; }

.rowfooter > div:first-child {
  padding-right: 45px; }

.rowfooter > div:last-child {
  padding-left: 45px; }

footer .h3 {
  margin-bottom: 30px;
  font-size: 20px; }

.lastfooter {
  background: #fff;
  text-align: center;
  padding: 10px 0; }

.lastfooter p {
  margin: 0; }

.lastfooter a {
  text-decoration: none !important;
  color: #707070;
  font-size: 14px; }

.dflexfooter p, .dflexfooter address {
  margin-bottom: 13px;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 25px; }

.devby p {
  font-size: 14px; }

.nav-footer {
  text-align: center;
  margin: 0.5rem 0; }
  .nav-footer li {
    display: inline-block;
    padding: 0 35px; }

.footer-logos {
  border-top: 1px solid rgba(98, 115, 132, 0.5);
  padding-top: 30px;
  padding-bottom: 30px; }

.footer-logos .col-md-6:first-child {
  display: flex;
  justify-content: center;
  column-gap: 3rem; }

/*All the pages*/
.contentloading {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #eeeeee;
  z-index: 9999999; }

.contentloading img {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0; }

.content {
  padding-top: 50px;
  padding-bottom: 35px; }

.content.topc {
  padding-top: 162px; }

.topbg {
  max-width: 2000px;
  margin: 0 auto;
  height: 440px;
  margin-top: 115px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-bottom: 13px solid #627384;
  position: relative; }

.captionbg {
  position: absolute;
  bottom: 0;
  right: 17%;
  background: #627384a0;
  color: #fff;
  padding: 14px 25px;
  border-left: 8px solid #758698; }

.captionbg p {
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: 25px; }

.biglinehd {
  position: relative;
  font-size: 60px;
  text-align: left; }

.biglinehd span {
  background: #fff;
  position: relative;
  z-index: 1;
  padding-right: 44px; }

.biglinehd::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 9px;
  height: 18px;
  width: 100%;
  background: #627384; }

.max-width-tops {
  max-width: 2000px;
  margin: 0 auto; }

#amenities {
  background-image: url(/images/background/amenities.png); }

#apartments {
  background-image: url(/images/background/apartments-new.webp); }

#gallery {
  background-image: url(/images/background/gallery.webp); }

#neighborhood {
  background-image: url(/images/background/neighborhood-new.webp); }

#amenities1 {
  background-image: url(/images/background/amenities-new/amenities1.jpg); }

#amenities2 {
  background-image: url(/images/background/amenities-new/amenities2-new.jpg); }

#amenities3 {
  background-image: url(/images/background/amenities-new/amenities3.jpg); }

#amenities4 {
  background-image: url(/images/background/amenities-new/amenities4.jpg); }

#amenities5 {
  background-image: url(/images/background/amenities-new/amenities5.jpg); }

#amenities6 {
  background-image: url(/images/background/amenities-new/amenities6.jpg); }

#amenities7 {
  background-image: url(/images/background/amenities-new/amenities7.jpg); }

#amenities8 {
  background-image: url(/images/background/amenities-new/amenities8.jpg); }

#amenities9 {
  background-image: url(/images/background/amenities-new/amenities9.jpg); }

#amenities10 {
  background-image: url(/images/background/amenities-new/amenities10.jpg); }

#amenities11 {
  background-image: url(/images/background/amenities-new/amenities11.jpg); }

#amenities12 {
  background-image: url(/images/background/amenities-new/amenities12.jpg); }

#amenities13 {
  background-image: url(/images/background/amenities-new/amenities13.jpg); }

#amenities14 {
  background-image: url(/images/background/amenities-new/amenities14.jpg); }

#amenities15 {
  background-image: url(/images/background/amenities-new/amenities15.jpg); }

.breadcrumb {
  background: none;
  padding: 0;
  margin-bottom: 35px;
  border-radius: 0; }

.breadcrumb div {
  display: inline; }

.breadcrumb li {
  margin-right: 3px;
  font-size: 14px;
  color: #000;
  font-weight: 400; }

.breadcrumb li.active {
  color: #4e4e4e; }

.cardamenity {
  background: #f4f4f4;
  border: 2px solid #e3e3e3;
  padding: 10px;
  overflow: hidden; }

.cardamenity img {
  float: left;
  margin-bottom: 10px; }

.detailfloor {
  float: right;
  max-width: 245px;
  width: 100%; }

.detailfloor .table th, .detailfloor .table td {
  border: none;
  padding: 7px 0; }

.cardamenity .row {
  clear: both; }

.cardamenity .btn-outline-primary {
  border: 3px solid #627384; }

.cardamenity .btn-secondary {
  border: 3px solid #6c757d; }

.iframemap {
  width: 100%;
  border: none;
  height: 682px;
  margin-bottom: 20px; }

.parentoasts {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 99999;
  align-items: center; }

.parentoasts .toast.show {
  height: 120px !important; }

.blueimp-gallery > .slides > .slide > .slide-content, .blueimp-gallery > .slides > .slide > .slide-content > img {
  max-width: 95% !important;
  max-height: 95% !important; }

.blueimp-gallery {
  background: rgba(0, 0, 0, 0.75) !important; }

.blueimp-gallery > .slides > .slide > .slide-content, .blueimp-gallery > .prev, .blueimp-gallery > .next, .blueimp-gallery > .close, .blueimp-gallery > .play-pause {
  color: #fff !important; }

.blueimp-gallery > .prev, .blueimp-gallery > .next, .blueimp-gallery > .close, .blueimp-gallery > .title, .blueimp-gallery > .play-pause, .blueimp-gallery > .indicator {
  display: block !important;
  text-indent: -9999px; }

.blueimp-gallery > .indicator, .blueimp-gallery > .play-pause {
  display: none !important; }

.blueimp-gallery > .title {
  top: auto !important;
  right: 15px;
  bottom: 15px;
  left: 15px !important;
  margin: 0 !important;
  text-align: center;
  background: rgba(0, 0, 0, 0.9);
  opacity: 1; }

.ulsitemap > li {
  margin-bottom: 12px; }

/*Large devices (laptops, 992px and up)*/
@media (min-width: 992px) and (max-width: 1199px) {
  .navbar.fixed-top {
    top: 43px; }
  .navbar .navbar-brand img {
    width: 174px; }
  .mosaicheader {
    width: 109px;
    height: 90px; }
  .navbar-nav a {
    font-size: 15px; }
  .cardamenity img {
    width: 210px; }
  .detailfloor {
    max-width: 195px; }
  .navbar.onfloat {
    top: 0; }
  .topbg {
    height: 360px;
    margin-top: 75px; } }

/*Medium devices (tablets, 768px and up)*/
@media (max-width: 991px) {
  .navbar.fixed-top {
    top: 41px; }
  .navbar-brand {
    margin-right: 0; }
  .navbar .navbar-brand img, .navbar.onfloat .navbar-brand img {
    width: 160px; }
  .mosaicheader {
    width: 83px;
    height: 69px; }
  .navbar-nav a, .navbar.onfloat .navbar-nav a {
    padding: 14px 0 10px 0 !important; }
  .navbar .navbar-nav a::after {
    display: none; }
  .navbar.onfloat .navbar-nav a::after {
    top: 27px; }
  .navbar.onfloat {
    top: 0; }
  .navbar-text.order-4 {
    order: initial !important; }
  footer .h3 {
    font-size: 19px; }
  .cardamenity img {
    float: none;
    display: block;
    margin: 0 auto 10px auto; }
  .detailfloor {
    max-width: 100%;
    float: none;
    text-align: center; }
  .cardamenity .btn {
    font-size: 12px;
    padding: 9px 5px 10px; }
  .iframemap {
    height: 706px; }
  .topbg {
    height: 360px;
    margin-top: 75px; }
  .biglinehd {
    font-size: 42px; }
  .carousel-indicators {
    display: none !important; }
  .footer-logos .col-md-6:first-child {
    flex-wrap: wrap;
    row-gap: 2rem; }
  .navbar-toggler {
    border: none;
    padding: 0 !important; } }

/*Mobile devices (landscape phones)*/
@media (max-width: 767px) {
  .headerphone {
    padding-top: 6px;
    padding-bottom: 5px;
    text-align: center;
    width: 100%; }
  .navbar.fixed-top {
    top: 36px;
    background-color: #fff !important;
    box-shadow: 0px 1px 3px 0px rgba(50, 50, 50, 0.6); }
  .navbar.onfloat {
    top: 0; }
  .content.topc {
    padding-top: 110px; }
  .detailfloor .table th, .detailfloor .table td {
    border: none;
    padding: 2px 0; }
  .detailfloor .h2 {
    margin-bottom: 9px; }
  .iframemap {
    height: 917px; }
  .topbg {
    height: 200px;
    margin-top: 79px; }
  .captionbg {
    right: 2%; }
  .captionbg p {
    font-size: 18px; }
  .biglinehd {
    font-size: 38px; }
  .dflexfooter {
    display: block !important;
    text-align: center; }
  .devby {
    padding-top: 20px; }
  .devby p {
    font-size: 16px; }
  #amenities {
    background-image: url(/images/background/amenities-mobile.webp); }
  #apartments {
    background-image: url(/images/background/apartments-new.webp); }
  #gallery {
    background-image: url(/images/background/gallery.webp); }
  #neighborhood {
    background-image: url(/images/background/neighborhood-new.webp); }
  #amenities1 {
    background-image: url(/images/background/amenities-mobile/amenities1.jpg); }
  #amenities2 {
    background-image: url(/images/background/amenities-mobile/amenities2-new.jpg); }
  #amenities3 {
    background-image: url(/images/background/amenities-mobile/amenities3.jpg); }
  #amenities4 {
    background-image: url(/images/background/amenities-mobile/amenities4.jpg); }
  #amenities5 {
    background-image: url(/images/background/amenities-mobile/amenities5.jpg); }
  #amenities6 {
    background-image: url(/images/background/amenities-mobile/amenities6.jpg); }
  #amenities7 {
    background-image: url(/images/background/amenities-mobile/amenities7.jpg); }
  #amenities8 {
    background-image: url(/images/background/amenities-mobile/amenities8.jpg); }
  #amenities9 {
    background-image: url(/images/background/amenities-mobile/amenities9.jpg); }
  #amenities10 {
    background-image: url(/images/background/amenities-mobile/amenities10.jpg); }
  #amenities11 {
    background-image: url(/images/background/amenities-mobile/amenities11.jpg); }
  #amenities12 {
    background-image: url(/images/background/amenities-mobile/amenities12.jpg); }
  #amenities13 {
    background-image: url(/images/background/amenities-mobile/amenities13.jpg); }
  #amenities14 {
    background-image: url(/images/background/amenities-mobile/amenities14.jpg); }
  #amenities15 {
    background-image: url(/images/background/amenities-mobile/amenities15.jpg); }
  .info {
    text-align: center; }
  .footer-logos .col-md-6:first-child {
    margin-bottom: 2rem; } }

/*Iphone6 Plus*/
@media (max-width: 480px) {
  .navbar.fixed-top {
    padding: 2px 15px; }
  .rowfooter > div:first-child {
    padding-right: 15px; }
  .rowfooter > div:last-child {
    padding-left: 15px;
    padding-top: 40px; }
  .biglinehd {
    font-size: 22px; } }

/*Most Androids Phones*/
@media (max-width: 380px) {
  .navbar-brand {
    font-size: 22px; }
  .content {
    padding-top: 40px;
    padding-bottom: 35px; } }

/*Iphone 5 or the smallest phone*/
